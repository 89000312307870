/*------------------------------ Css General----------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: 'Gibson-Bold';
  src: url('./assets/fonts/Gibson-Bold.otf');
}
@font-face {
  font-family: 'Gibson-BoldItalic';
  src: url('./assets/fonts/Gibson-BoldItalic.otf');
}
@font-face {
  font-family: 'Gibson-Book';
  src: url('./assets/fonts/Gibson-Book.otf');
}
@font-face {
  font-family: 'Gibson-BookItalic';
  src: url('./assets/fonts/Gibson-BookItalic.otf');
}
@font-face {
  font-family: 'Gibson-Heavy';
  src: url('./assets/fonts/Gibson-Heavy.otf');
}
@font-face {
  font-family: 'Gibson-HeavyItalic';
  src: url('./assets/fonts/Gibson-Heavy.otf');
}
@font-face {
  font-family: 'Gibson-Italic';
  src: url('./assets/fonts/Gibson-Italic.otf');
}
@font-face {
  font-family: 'Gibson-Light';
  src: url('./assets/fonts/Gibson-Light.otf');
}
@font-face {
  font-family: 'Gibson-LightItalic';
  src: url('./assets/fonts/Gibson-LightItalic.otf');
}
@font-face {
  font-family: 'Gibson-Medium';
  src: url('./assets/fonts/Gibson-Medium.otf');
}
@font-face {
  font-family: 'Gibson-MediumItalic';
  src: url('./assets/fonts/Gibson-MediumItalic.otf');
}
@font-face {
  font-family: 'Gibson-Regular';
  src: url('./assets/fonts/Gibson-Regular.otf');
}
@font-face {
  font-family: 'Gibson-SemiBold';
  src: url('./assets/fonts/Gibson-SemiBold.otf');
}
@font-face {
  font-family: 'Gibson-SemiBoldItalic';
  src: url('./assets/fonts/Gibson-SemiBoldItalic.otf');
}
@font-face {
  font-family: 'Gibson-Thin';
  src: url('./assets/fonts/Gibson-Thin.otf');
}
@font-face {
  font-family: 'Gibson-ThinItalic';
  src: url('./assets/fonts/Gibson-ThinItalic.otf');
}
:root {
  --red: #dc3545;
  --white: #ffffff;
  --black: #000000;
  --blue: #1946bb;
  --light-blue: #4b73f0;
  --guinda: #4A001F;
  --rosaClaro:#FFC3D0;
  --rosaAlert:#FFE6EC;
  --morado:#6A0F49;
  --orage: #ffc107;
  --warning:#F9E8C6;
  --light-orange: #ffaf17;
  --green: #198754;
  --primary-text: #050505;
  --secondary-text: #65676b;
  --input-gray: #f0f2f5;
  --gray-00: #f5f6f7;
  --gray-05: #f2f3f5;
  --gray-10: #ebedf0;
  --gray-20: #dadde1;
  --gray-25: #ccd0d5;
  --gray-30: #bec3c9;
  --gray-45: #8d949e;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1c1e21;
  --lightblue-alpha: rgba(75, 115, 240, 0.1);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --overlay-alpha-80: rgba(244, 244, 244, 0.8);
  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
}

body {
  font-family: "Gibson-Regular";
  letter-spacing: 1.1px;
  font-weight: 500;
}

.logoGif {
  width: 300px;
}

.logo-text{
  margin-left: 10px;
}

.borderBottom--Rosa {
  border-bottom: 6px solid var(--rosaClaro);
}

.borderBottom--gris {
  border-bottom: 1px solid var(--gray-30);
}

.divider{
  border-bottom: 6px var(--morado);
}

.contenedorModalUrs::-webkit-scrollbar {
  -webkit-appearance: none;
}

.pill {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  margin: 5px !important;
  border: 1px solid var(--guinda) !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  background-color: var(--guinda)!important;
  color:var(--white)!important;
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10) !important;
}

.pill:hover{
  border: 1px solid var(--guinda)!important;
  background-color:var(--rosaClaro)!important;
  color: var(--guinda) !important;
}

.cta--guinda {
  background-color: var(--guinda) !important;
  color: white !important;
}

.my-swal {
  z-index: 999999999;
}

.secondary{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  margin: 5px !important;
  border: 1px solid var(--white) !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  background-color: var(--gray-45) !important;
  color:var(--white)!important;
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10) !important;
}

.secondary:hover{
  border: 1px solid var(--white)!important;
  background-color:var(--gray-90)!important;
  color: var(--white) !important;
}

.border-top--rosa{
  border-top: 5px solid var(--rosaClaro);
}
.logoGris{
  width: 240px;
  height: 120px;
}
.logoSello{
  width: 130px;
}
.redes{
  width: 400px;
}

i{
  color: var(--secondary-text);
}
/*------------------------------ Background ----------------------- */
.bg-white{
  background-color: var(--white);
}
.bg-guinda{
  background-color: var(--guinda);
}
.bg-armarillo{
  background-color: var(--warning);
}
.bg-morado{
  background-color: var(--morado);
}
.bg-rosaClaro{
  background-color: var(--rosaClaro);
}
.bg-rosaAlert{
  background-color: var(--rosaAlert);
}
.bg-blue{
  background-color: var(--blue);
}
.bg-light-blue{
  background-color: var(--light-blue);
}
.bg-orange{
  background-color: var(--orage);
}
.bg-light-orange{
  background-color: var(--light-orange);
}
.bg-green{
  background-color: var(--green);
}
.bg-gray-00 {
  background-color: var(--gray-00);
}
.bg-gray-05 {
  background-color: var(--gray-05);
}
.bg-gray-10 {
  background-color: var(--gray-10);
}
.bg-gray-20 {
  background-color: var(--gray-20);
}
.bg-gray-25 {
  background-color: var(--gray-25);
}
.bg-gray-30 {
  background-color: var(--gray-30);
}
.bg-gray-45 {
  background-color: var(--gray-45);
}
.bg-gray-70 {
  background-color: var(--gray-70);
}
.bg-gray-80 {
  background-color: var(--gray-80);
}
.bg-gray-90 {
  background-color: var(--gray-90);
}
.bg-gray-90 {
  background-color: var(--gray-100);
}

/*------------------------------ Textos Basado Tamaño ----------------------- */

.text-big {
  font-size: 1.62rem;
}
.text-medium {
  font-size: 1.18rem !important;
}
.text {
  font-size: 1rem !important;
}
.text-small {
  font-size: 13px;
}
/*------------------------------ Texto primario y secundario ----------------------- */
.text-primary {
  color: var(--primary-text) !important;
}
.text-secondary {
  color: var(--secondary-text) !important;
}

/*------------------------------ Textos de tipo negritas  ----------------------- */
.text-bold {
  font-weight: 700;
}
.text-bolder {
  font-weight: 900;
}
/*------------------------------ textos de colores ----------------------- */

.text-guinda{
  color: var(--guinda);
}
.text-morado{
  color: var(--morado);
}

.text-white{
  color: var(--white) !important;
}

.text-gray{
  color: var(--gray-45)
}
.text-light-blue{
  color: var(--light-blue);
}
.text-orange{
  color: var(--orage);
}
.text-light-orange{
  color: var(--light-orange);
}
.text-red{
  color: var(--red);
}
.text-blue{
  color: var(--blue);
}

.text-head{
  font-size: 30px;
}
/*------------------------------ textos de colores ----------------------- */
.GibsonBold{
  font-family: 'Gibson-Bold';
}
.GibsonBoldItalic{
  font-family: 'Gibson-BoldItalic';
}
.GibsonBook{
  font-family: 'Gibson-Book';
}
.GibsonBookItalic{
  font-family: 'Gibson-BookItalic';
}
.GibsonHeavy{
  font-family: 'Gibson-Heavy';
}
.GibsonHeavyItalic{
  font-family: 'Gibson-HeavyItalic';
}
.GibsonItalic{
  font-family: 'Gibson-Italic';
}
.GibsonLight{
  font-family: 'Gibson-Light';
}
.GibsonLightItalic{
  font-family: 'Gibson-LightItalic';
}
.GibsonMedium{
  font-family: 'Gibson-Medium';
}
.GibsonMediumItalic{
  font-family: 'Gibson-MediumItalic';
}
.GibsonRegular{
  font-family: 'Gibson-Regular';
}
.GibsonSemiBold{
  font-family: 'Gibson-SemiBold';
}
.GibsonSemiBoldItalic{
  font-family: 'Gibson-SemiBoldItalic';
}
.GibsonThin{
  font-family: 'Gibson-Thin';
}
.GibsonThinItalic{
  font-family: 'Gibson-ThinItalic';
}

.colored-toast.swal2-icon-error {
  background-color: var(--red) !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}
/*------------------------------ Footer ----------------------- */

.border-top--rosa {
  border-top: 5px solid var(--rosaClaro);
}

.logoGris {
  width: 150px;
}

.logoSello {
  width: 70px;
}

.redes {
  width: 400px;
}

.backgrounPlaca {
  background-image: url(assets/img/placa.png);
  background-size: cover;
  height: 25px;
}